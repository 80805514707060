<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">

                <div class="card card-secondary">
                    <div class="card-header">{{ formTitle }}</div>
                    <ValidationObserver v-slot="{ invalid }">
                        <form @submit="saveForm">
                        <div class="card-body">
                            <div class="row">
                                <div class="form-group col-sm-3">
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <label for="payment_date">Date *</label>
                                        <input type="datetime-local" class="form-control" id="payment_date" placeholder="Select date of payment" v-model="formData.payment_date" required>
                                        <span>{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="form-group col-sm-2">
                                    <label for="ref">Reference</label>
                                    <input type="text" class="form-control" id="ref" placeholder="Enter a reference number" v-model="formData.ref">
                                </div>
                                <div class="form-group col-sm-2">
                                    <div class="row">
                                        <div :class="formData.image? 'col-sm-6': 'col-sm-12'">
                                            <label for="upload_image">Image</label>
                                            <input type="file" class="form-control" id="upload_image" ref="upload_image" accept=".jpg,.jpeg,.png,.gif" v-on:change="setUploadFile">
                                        </div>
                                        <div class="col-sm-6" v-if="formData.image">
                                            <img class="img-thumbnail img-md mt-2" :src="$apiBaseURL + '/' + formData.image" v-if="formData.image" />
                                            <button type="button" class="btn btn-xs text-danger" v-on:click="unsetUploadFile"><i class="far fa-times-circle"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-sm-2">
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <label for="payment_method_id">Payment Method *</label>
                                        <select class="form-control" id="payment_method_id" v-model="formData.payment_method_id" required>
                                            <option v-for="paymentMethod in paymentMethods" :key="paymentMethod.id" :value="paymentMethod.id">{{ paymentMethod.name }}</option>
                                        </select>
                                        <span>{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="form-group col-sm-3">
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <label for="store_id">Store *</label>
                                        <select class="form-control" id="store_id" v-model="formData.store_id" required>
                                            <option v-for="store in stores" :key="store.id" :value="store.id">{{ store.name }}</option>
                                        </select>
                                        <span>{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-sm-3"></div>
                                <div class="col-sm-3">
                                    <div v-if="selectedPaymentMethod && selectedPaymentMethod.isCheque">
                                        <ValidationProvider v-slot="{ errors }" rules="required">
                                            <label for="cheque_no">Cheque No *</label>
                                            <input type="text" class="form-control" id="cheque_no" placeholder="Enter the cheque number" v-model="formData.cheque_no" required>
                                            <span>{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div v-if="selectedPaymentMethod && selectedPaymentMethod.isCheque">
                                        <ValidationProvider v-slot="{ errors }" rules="required">
                                            <label for="cheque_date">Cheque Date *</label>
                                            <input type="date" class="form-control" id="cheque_date" placeholder="Enter the cheque date" v-model="formData.cheque_date" required>
                                            <span>{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div v-if="selectedPaymentMethod && selectedPaymentMethod.isCheque">
                                        <ValidationProvider v-slot="{ errors }" rules="required">
                                            <label for="cheque_bank">Cheque Bank *</label>
                                            <input type="text" class="form-control" id="cheque_bank" placeholder="Enter the cheque bank" v-model="formData.cheque_bank" required>
                                            <span>{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                    <div v-if="selectedPaymentMethod && (!selectedPaymentMethod.isCheque && !selectedPaymentMethod.isCash)">
                                        <ValidationProvider v-slot="{ errors }" rules="required">
                                            <div class="form-group">
                                                <label for="other_description">Description *</label>
                                                <textarea class="form-control" id="other_description" placeholder="Enter a description of the transaction" v-model="formData.description" required></textarea>
                                            </div>
                                            <span>{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="card">
                                        <div class="card-body">

                                            <div class="form-group">
                                                <label for="payment_method_id">From *</label>
                                                <vSelect @search="searchFrom" :filterable="false" :clearable="false" :options="fromAccounts" v-model="fromAccount" v-on:option:selected ="setFrom">
                                                    <template v-slot:option="option">
                                                        {{ option.label }}
                                                        <div class="text-sm font-italic">{{ option.type }}</div>
                                                    </template>
                                                    <template v-slot:no-options="{ search, searching }">
                                                        <template v-if="searching">
                                                            No results found for <em>{{ search }}</em>.
                                                        </template>
                                                        <em style="opacity: 0.5;" v-else>Start typing to search.</em>
                                                    </template>
                                                </vSelect>
                                            </div>
                                            <div class="form-group">
                                                <ValidationProvider v-slot="{ errors }" rules="required|positiveFPN">
                                                    <label for="amount">Debit Amount *</label>
                                                    <input type="text" class="form-control" id="amount" placeholder="Enter an amount" v-model="formData.amount" required>
                                                    <span>{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="form-group">
                                                <label for="description">Description</label>
                                                <input type="text" class="form-control" id="description" placeholder="Enter a description" v-model="formData.description">
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="form-group">
                                                <label for="payment_method_id">To *</label>
                                                <vSelect @search="searchTo" :filterable="false" :clearable="false" :options="toAccounts" v-on:option:selected ="setTo">
                                                    <template v-slot:option="option">
                                                        {{ option.label }}
                                                        <div class="text-sm font-italic">{{ option.type }}</div>
                                                    </template>
                                                    <template v-slot:no-options="{ search, searching }">
                                                        <template v-if="searching">
                                                            No results found for <em>{{ search }}</em>.
                                                        </template>
                                                        <em style="opacity: 0.5;" v-else>Start typing to search.</em>
                                                    </template>
                                                </vSelect>
                                            </div>
                                            <div class="form-group">
                                                <ValidationProvider v-slot="{ errors }" rules="positiveFPN">
                                                    <label for="to_amount">Credit Amount *</label>
                                                    <input type="text" class="form-control" id="to_amount" placeholder="Enter an amount" v-model="toAmount">
                                                    <span>{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </div>
                                            <div class="form-group">
                                                <label for="to_description">Description</label>
                                                <input type="text" class="form-control" id="to_description" placeholder="Enter a description" v-model="toDescription">
                                            </div>
                                            <button type="button" class="btn btn-secondary btn-xs float-right" v-on:click="addTo"><i class="fas fa-plus"></i> Add</button>
                                            <div class="clearfix"></div>

                                            <table class="table table-bordered table-sm mt-2">
                                                <thead>
                                                <tr>
                                                    <th style="width: 50%;">To</th>
                                                    <th class="text-right">Credit</th>
                                                    <th>Description</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="transaction in formData.payment_transactions" :key="transaction.id">
                                                    <td>{{ transaction.to_name }}</td>
                                                    <td class="text-right">{{ transaction.amount }}</td>
                                                    <td>{{ transaction.to_description }}</td>
                                                    <td><button type="button" class="btn btn-danger btn-xs" v-on:click="removeTo(transaction.sl)"><i class="far fa-times-circle"></i></button></td>
                                                </tr>
                                                <tr>
                                                    <td class="text-right">Total</td>
                                                    <td class="text-right text-bold">{{ toTotal }}</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="card-footer">
                            <button type="submit" class="btn btn-sm btn-primary float-right" v-if="saveBtnEnabled" :disabled="invalid"><i class="fas fa-save"></i> Save</button>
                        </div>
                    </form>
                    </ValidationObserver>
                </div>

            </div><!--/. container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import Vue from 'vue'
    import dayjs from 'dayjs';
    import customParseFormat from 'dayjs/plugin/customParseFormat';
    dayjs.extend(customParseFormat);

    import vSelect from 'vue-select'
    import 'vue-select/dist/vue-select.css';

    import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
    import { required } from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'This field is required'
    });
    extend('positiveFPN', value => {
        let regexFloatingPoint = /^[+-]?([0-9]*[.])?[0-9]+$/i;
        return value >= 0 && regexFloatingPoint.test(value)? true: 'This field must be a positive number';
    });
    import {PaymentService} from "@/services/payment-service";
    import {StoreService} from "@/services/store-service";
    import {AccountService} from "@/services/account-service";
    import {getDateToday, getDateTimeToday, convertLocalToUTC, convertUTCToLocal} from "@/helpers/dateHelper";

    let paymentService = new PaymentService();
    let storeService = new StoreService();
    let accountService = new AccountService();
    export default {
        name: 'Payment_view',
        data: function(){
            return {
                pageName: 'Payment',
                segments: [
                    { link: true, text: 'Payments', routeName: 'App.Payments' },
                    { link: false, text: 'Payment' },
                ],
                paymentMethods: [],

                fromAccounts: [],
                fromAccount: null,
                from_id: null,
                from_type: null,
                from_label: null,

                toAccounts: [],
                toTotal: 0,
                to_id: null,
                to_type: null,
                to_label: null,
                toAmount: null,
                toDescription: null,

                stores: [],

                upload_image: null,
                formData: {
                    id: 0,
                    amount: null,
                    payment_date: getDateTimeToday() ,
                    ref: null,
                    invoice_no: null,
                    type: null,
                    payment_method_id: null,
                    cheque_no: null,
                    cheque_date: getDateToday(),
                    cheque_bank: null,
                    description: null,
                    image: null,
                    store_id: null,
                    payment_transactions: [],
                },
                saveBtnEnabled: false,
                formTitle: ''
            }
        },
        props: {},
        components: {
            BreadCrumb, vSelect, ValidationObserver, ValidationProvider
        },
        computed: {
            selectedPaymentMethod: function () {
                return this.paymentMethods.find(x => x.id === this.formData.payment_method_id);
            }
        },
        methods:{
            loadPaymentMethods: async function () {
                let loader = this.$loading.show({container: this.$refs.formContainer});
                let response = await paymentService.getPaymentMethods(false);
                if (response.isSuccess) {
                    this.paymentMethods = response.paymentMethods;
                    if (this.paymentMethods.length > 0 && !this.formData.payment_method_id) {
                        this.formData.payment_method_id = this.paymentMethods[0].id;
                    }
                } else {
                    Vue.$toast.open({message: response.message, type: 'error'});
                    await this.$router.push({name: 'App.Payments'});
                }
                loader.hide();
            },
            loadStores: async function () {
                let loader = this.$loading.show({container: this.$refs.formContainer});
                let response = await storeService.getStores(false);
                if (response.isSuccess) {
                    this.stores = response.stores;
                    if (this.stores.length > 0 && !this.formData.store_id) {
                        this.formData.store_id = this.stores[0].id;
                    }
                } else {
                    Vue.$toast.open({message: response.message, type: 'error'});
                    await this.$router.push({name: 'App.Payments'});
                }
                loader.hide();
            },
            searchFrom: async function (search, loading) {
                if (search.length) {
                    loading(true);
                    let response = await accountService.searchAggregatedAccount({
                        keyword: search,
                        type: this.$route.params.type,
                        section: 'from'
                    });
                    this.fromAccounts = response.accounts;
                    loading(false);
                }
            },
            setFrom: function(account){
                this.from_id = account.account_id;
                this.from_label =  account.label;
                this.from_type =  account.type;
            },
            searchTo: async function (search, loading) {
                if (search.length) {
                    loading(true);
                    let response = await accountService.searchAggregatedAccount({
                        keyword: search,
                        type: this.$route.params.type,
                        section: 'to'
                    });
                    this.toAccounts = response.accounts;
                    loading(false);
                }
            },
            setTo: function(account){
                this.to_id = account.account_id;
                this.to_label =  account.label;
                this.to_type =  account.type;
            },
            addTo(){
                if(this.fromAccount && this.to_id && this.to_label && this.to_type && this.formData.amount > 0 && this.toAmount) {
                    this.formData.payment_transactions.push({
                        amount: this.toAmount,
                        from_id: this.from_id,
                        from_type: this.from_type,
                        from_description: this.formData.description,
                        from_name: this.from_label,
                        to_id: this.to_id,
                        to_type: this.to_type,
                        to_description: this.toDescription,
                        to_name: this.to_label
                    });

                    this.to_id = null;
                    this.to_label =  null;
                    this.to_type =  null;
                    this.toDescription = '';

                    this.calculateTo();
                }else{
                    Vue.$toast.open({ message: 'Please fill the required fields to add an entry!',  type: 'error'});
                }
            },
            calculateTo(){
                this.toTotal = 0;
                for(let i = 0; i < this.formData.payment_transactions.length; i++){
                    this.toTotal += Number(this.formData.payment_transactions[i].amount);
                    this.formData.payment_transactions[i]['sl'] = i;
                }
                this.saveBtnEnabled = Number(this.formData.amount) === Number(this.toTotal);
            },
            removeTo(sl){
                if(confirm("Are you sure?")) {
                    this.formData.payment_transactions = this.formData.payment_transactions.filter(obj => obj.sl !== sl);
                    this.calculateTo();
                }
            },
            async loadSavedData() {
                if (this.$route.params.id !== 0) {
                    this.formData.id = this.$route.params.id;
                    let loader = this.$loading.show();
                    let response = await paymentService.getPayment(this.formData.id, 'edit');
                    if (response.isSuccess) {
                        this.formData = response.payment;
                        this.formData.payment_date =  convertUTCToLocal(response.payment.payment_date);
                        //console.log(this.formData.payment_date );
                        if (response.payment.payment_transactions.length > 0) {
                            let first_row = response.payment.payment_transactions[0];
                            this.from_id = first_row.from_id;
                            this.from_label = first_row.from_name;
                            this.from_type = first_row.from_type;
                            this.fromAccount = {
                                id: first_row.from_id,
                                label: first_row.from_name,
                                type: first_row.from_type
                            };
                            this.fromAccounts.push(this.fromAccount);
                        } else {
                            Vue.$toast.open({message: 'Payment has invalid properties!', type: 'error'});
                            await this.$router.push({name: 'App.Payments'});
                        }
                        this.calculateTo();
                    } else {
                        Vue.$toast.open({message: response.message, type: 'error'});
                        await this.$router.push({name: 'App.Payments'});
                    }
                    loader.hide();
                } else {
                    this.formData.type = this.$route.params.type;
                }
            },
            setUploadFile(){
                this.upload_image = this.$refs.upload_image.files[0];
            },
            async saveForm(e) {
                e.preventDefault();
                let loader = this.$loading.show();
                this.formData.payment_date = convertLocalToUTC(this.formData.payment_date);
                paymentService.formDataPayment = this.formData;
                let response = await paymentService.savePayment(this.upload_image);
                if (response.isSuccess) {
                    Vue.$toast.open({message: response.message, type: 'success'});
                    await this.$router.push({name: 'App.Payments'});
                }else{
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();
            },
            unsetUploadFile(){
                if(confirm("Are you sure?")) {
                    this.formData.image = null;
                }
            }
        },
        mounted() {
            this.formTitle = this.$route.params.id === 0? Object.keys(this.$globalEnums.paymentTypes).find(key => this.$globalEnums.paymentTypes[key] === this.$route.params.type) : 'Edit';
            this.loadPaymentMethods();
            this.loadSavedData();
            this.loadStores();
        }
    }
</script>

<style scoped>

</style>
