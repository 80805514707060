import axios from "axios";
import Vue from "vue";
import {processHttpErrors} from "@/helpers/commonHelper";
import {saveFile} from "@/helpers/mediaHelper";
//import dayjs from "dayjs";

export class PaymentService{
    formDataPayment = {};
    formDataPaymentMethod = {};
    constructor() {
        this.formDataPayment = {};
        this.formDataPaymentMethod = {};
    }


    async getPayment(id, mode){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.payment.payment, {
            id: id,
            mode: mode,
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, payment: response.data.payment};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', payment: null};
            });
    }
    async savePayment(upload_image){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.payment.paymentSave, this.formDataPayment).then(async (response) => {
            if(upload_image) {
                if (!await saveFile(response.data.id, upload_image, Vue.prototype.$globalSettings.api.endpoints.payment.paymentSaveFile)) {
                    return {isSuccess: false, message: 'Image upload failed!', payment: null};
                }
            }
            return {isSuccess: response.data.status === 'success', message: response.data.msg, payment: response.data.payment};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', payment: null};
        });
    }

    async searchPayment(filters){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.payment.payments, filters)
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, payments: response.data.payments};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', payments: []};
            });
    }

    async delete(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.payment.paymentDelete, {
            id: id
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }

    //Payment method
    async getPaymentMethods(fetch){
        let paymentMethods = Vue.prototype.$store.getters['common/paymentMethods'];
        if(paymentMethods.length <= 0 || fetch){
            return await axios.post(Vue.prototype.$globalSettings.api.endpoints.paymentMethod.paymentMethods)
                .then((response) => {
                    if(response.data.status === 'success'){
                        paymentMethods = response.data.paymentMethods;
                        Vue.prototype.$store.dispatch('common/setPaymentMethods', {value: paymentMethods});
                    }
                    return {isSuccess: response.data.status === 'success', message: response.data.msg, paymentMethods: paymentMethods};
                })
                .catch((error) => {
                    processHttpErrors(error, true);
                    return {isSuccess: false, message: 'Something went wrong!', paymentMethods: []};
                });
        }
        return {isSuccess: true, message: '', paymentMethods: paymentMethods};
    }
    async getPaymentMethod(id, mode){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.paymentMethod.paymentMethod, {
            id: id,
            mode: mode,
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, paymentMethod: response.data.paymentMethod};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', paymentMethod: null};
            });
    }
    async savePaymentMethod(){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.paymentMethod.paymentMethodSave, this.formDataPaymentMethod).then(async (response) => {
            await this.getPaymentMethods(true);
            return {isSuccess: response.data.status === 'success', message: response.data.msg, paymentMethod: response.data.paymentMethod};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', paymentMethod: null};
        });
    }

    async deletePaymentMethod(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.paymentMethod.paymentMethodDelete, {
            id: id
        })
            .then(async (response) => {
                await this.getPaymentMethods(true);
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }
}